import React from "react";
import { Link, graphql } from "gatsby";

import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql`
  query NotFoundPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      address
      title
      facebook
      email
      phone
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const NotFoundPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const address = site.address;
  const facebook = site.facebook;
  const email = site.email;
  const phone = site.phone;

  return (
    <Layout
      siteTitle={site.title}
      textWhite={true}
      address={address}
      facebook={facebook}
      email={email}
      phone={phone}
    >
      <SEO title="404: Not found" />
      <p className="text-primary text-center">
        You just hit a route that doesn&#39;t exist... the sadness.
      </p>
      <Link to="/">Back to home</Link>
    </Layout>
  );
};

export default NotFoundPage;
